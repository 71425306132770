// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import 'css/site'

require("@rails/ujs").start()
import $ from 'jquery';
require("bootstrap")
require("@rails/activestorage").start()
require("channels")
require("@nathanvda/cocoon")
import "datatables.net";

var dataTable = null

$(window).on('load', function(){
  $("[data-form-prepend]").on('click', function (e) {
    var obj = $($(this).attr("data-form-prepend"));
    obj.find("input, select, textarea").each(function () {
      $(this).attr("name", function () {
        return $(this)
          .attr("name")
          .replace("new_record", new Date().getTime());
      });
    });
    obj.insertBefore(this);
    return false;
  });

  dataTable = $('#user-table').DataTable(
    {
      stateSave: true
    }
  );

  $('#informedConsent').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget) // Button that triggered the modal
    var prescreening_url = button.data('prescreening') // Extract info from data-* attributes
    // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
    // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.
    var modal = $(this)
    modal.find('a#agree').attr("href", prescreening_url)
  })
  
});
